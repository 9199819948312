<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <div class="card" v-for="item in data.records" :key="item" @click="$router.push('/dynamicDetails?id='+item.id)">
          <img :src="item.pic">
          <div class="text">
            <h4>{{item.title}}</h4>
            <p>{{item.brief}}</p>
            <p>{{item.author}} &nbsp; &nbsp; &nbsp;来源：{{item.source}}</p>
          </div>
        </div>
        <pagination v-on:toPage="toPage" :this-page="current" :pages="data.pages"></pagination>
      </div>
      <div slot="rightDiv">
        <card ref="card">
          <div slot="heard" class="search">
            <input placeholder="搜索">
            <i><img src="../../assets/image/icon/search.png"></i>
          </div>
        </card>
        <card ref="card0"></card>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";
import pagination from "@/components/public/pagination";
import card from "@/components/public/card";
export default {
  name: "dynamic",
  components:{
    leftAndRight,
    pagination,
    card
  },
  data(){
    return{
      current:1,
      data:{},
    }
  },
  mounted() {
    this.getDataList()
  },
  methods:{
    getDataList() {
      this.$http.get('/safe/getArticleList', {
        params: {
          columnName: '会员动态',
          belong: 1,
          current: this.current,
          size: 5
        }
      }).then((res) => {
        let forms=res.data.records
        for(let i in forms){
          let pic =forms[i].pic
          if(pic!=null){
            forms[i].pic=this.$constContent.imgUrl+pic
          }
          let time = forms[i].time;
          let date = new Date(time);
          forms[i].time=this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
        }
        this.data=res.data
      })


      const list = ["热门资讯", "往期精选"]

      for (let index in list) {
        const ths = this
        this.$http.get('/safe/getIndexInformation', {
          params: {
            name: list[index],
          }
        }).then((res) => {
          let forms = res.data

          for (let i in forms) {
            let pic = forms[i].pic
            if (pic != null) {
              forms[i].pic = this.$constContent.imgUrl + pic
            }
            let time = forms[i].time;
            let date = new Date(time);
            res.data[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
          }
          let Cards=[]
          let Card={}
          Card.title=list[index]
          Card.id=0
          Card.content = res.data
          Cards.push(Card)
          ths.$nextTick(() => {
            if(index==0){
              ths.$refs.card.init(Cards)
            }else {
              ths.$refs.card0.init(Cards)
            }
          })
        })
      }
    },
    toPage(e){
      this.current=e
      this.getDataList()
    },

  }
}
</script>

<style scoped>
.card{
  width: 96%;
  padding:2%;
  display: flex;
  border-bottom: 1px solid #eeeeee;
}
.card img{
  width: 35%;
  float: left;
}
.text{
  width: 61%;
  margin: 0 2%;
  float: left;
}
.text p{
  font-size: 14px;
  color: #999999;
}
.search{
  width: 100%;
  height: 40px;
  position: relative;
}
.search input{
  height: 38px;
  width: calc(100% - 20px);
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #dfdfdf;
}
.search i img{
  width: 15px;
  height: 15px;
  position: absolute;
  top: 12px;
  right: 12px;
}
img{
  width: 100%;
}

</style>